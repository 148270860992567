import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Utils } from '@app/utils';

import { SharedUtils } from '@rehub-shared/utils/utils';
import { ProtocolUtils } from '@rehub-shared/utils/protocol-utils';
import { I18nService } from '@app/i18n';

@Component({
  selector: 'rehub-patient-today-exercises',
  templateUrl: './patient-today-exercises.component.html',
  styleUrls: ['./patient-today-exercises.component.scss'],
  host: { class: 'card-rounded container-fluid bg-white d-block cy-patient-today-exercises', /*style: 'max-height: 375px;' */ }
})
export class PatientTodayExercisesComponent implements OnInit {

  Utils = Utils;
  SharedUtils = SharedUtils;

  @Input() exercises: any;
  @Input() protocols: any;
  @Input() exerciseGroups: any;
  @Input() now: any
  @Input() showTotalExercises: boolean = false;
  @Input() showAllExercises: boolean = false;
  @Input() hideFirstExercise: boolean = false;
  @Input() responsiveExercise: boolean = false;
  @Input() hasSensor: boolean = true;
  @Input() isGrouped: boolean = true;

  @Output() startExercise = new EventEmitter();

  titleExercises: string;
  firstAvailableTime: string;

  constructor(
    private i18nService: I18nService
  ) {}

  ngOnInit() {

    this.exercises.forEach((exercise: any) => {
      exercise.jointTranslation = ProtocolUtils.getJointTranslation(this.i18nService, exercise.joint);
      exercise.protocolTranslation = ProtocolUtils.getProtocolTranslation(this.i18nService, this.protocols[exercise.protocol]);
    });

    let totalExercises = this.exercises.length;

    this.titleExercises = totalExercises + ' ' + this.i18nService.translate(totalExercises == 1 ? 'general_exercise' : 'general_exercises');

  }

  start(exercise: any) {
    if(!this.isClickable()) return;

    let data: any = {
      exercise: exercise,
      protocol: this.protocols[exercise.protocol]
    }
    this.startExercise.emit(data);
  }

  isClickable(){
    return this.startExercise.observers.length > 0;
  }


}
